section {
	display: flex;
	height: 100vh;
	width: 100%;
	border-bottom: 1px solid rgb(46, 43, 43);
}

section:nth-child(even) {
	flex-direction: row-reverse;
}
section img,
section div {
	height: auto;
	width: 100%;
}

.portfolioLinks{
    width:15vw;
    height:9vw;
    margin-right:3vw;
    margin-left:1vw
}

section div {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
	padding: 1em;
    top:0%
}
h2{
    font-family: Verdana, Tahoma, sans-serif;
}
h3{
    font-family: Verdana, Tahoma, sans-serif;
}

h4{
    font-family: Verdana, Tahoma, sans-serif;
    font-size: x-large;
}

.smallp{
    font-family: Verdana, Tahoma, sans-serif;
    font-size: medium;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items:flex-start;
    justify-content:flex-start;
}
.projectButtons{
    margin-top:-36vw;
}

.projectA{
    margin-top: -55vw;
}
.projectB{
    margin-top: 1vw;
}
.projectC{
    margin-top:-20vw;
}
.projectsize1{
    height:100vw;
}
.projectsize2{
    height:250vw;
}

.Button{
    display: inline-block;
    background-color: rgb(128, 189, 242);
    color: rgb(19, 13, 75);
    font-size: 1.3vw;
    height:8vw;
    width:28vw;
    padding: 1vw 2vw;
    border: 0.2vw solid #153083;
    border-radius: 0.5vw;
    margin: 2vw 1vw;
    cursor: pointer;
    align-items: flex-start;
	justify-content: flex-start;
  }
  .Button:hover{
    animation: pulsate 1s
    ease-in-out;
  }
  .Button:disabled {
    color: rgb(218, 209, 184);
    border: 3px solid #97a5cd;
    background-color: rgb(145, 164, 219);
    animation: pulsate 0s
    ease-in-out;
  }
  
  @keyframes pulsate{
    0%{
      box-shadow:
      0 0 2vw #15258c,
      0 0 5vw #1842cc;
    }
  }

@media screen and (min-device-width: 375px) and (max-width: 812px) {
	section,
	section:nth-child(even) {
		flex-direction: row;
        height:100vh;
	}
    section div {
        width:fit-content;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: space-around;
        padding: 1em;
        font-size: x-small;
    }
    .portfolioLinks{
        width:0.5vw;
        height:0.2vw;
        margin-right:0.3vw;
        margin-left:0.1vw
    }
    .projectButtons{
        margin-top:16vw;
    }
    .projectsize1{
        height:180vw;
    }
    .projectsize2{
        height:690vw;
    }
    h2{
        font-family: Verdana, Tahoma, sans-serif;
        font-size: small;
    }
    h3{
        font-family: Verdana, Tahoma, sans-serif;
        font-size: small;
    }
    h4{
        font-family: Verdana, Tahoma, sans-serif;
        font-size: small;
    }
    .projectA{
        margin-top: -400vw;
        font-size: x-small;
    }
    .projectB{
        margin-top: -190vw;
        font-size: x-small;
    }
    .projectC{
        margin-top: -40vw;
        font-size: x-small;
    }
    .projectButtonAlign{
        width:fit-content;
        display: flex;
        margin-top: -8vw;
        flex-direction: column;
        align-items:flex-start;
        justify-content:flex-start;
        font-size: x-small;
    }
    section p{
        width:fit-content;
        display: flex;
        flex-direction: row;
        align-items:flex-start;
        justify-content:flex-start;
        font-size: x-small;
    }

    .Button{
        display: inline-block;
        background-color: rgb(128, 189, 242);
        color: rgb(19, 13, 75);
        font-size: 2.4vw;
        height:11vw;
        width:58vw;
        padding: 0.25em 0.5em;
        border: 0.2vw solid #153083;
        border-radius: 0.5vw;
        margin: 10px 0px;
        cursor: pointer;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .Button:hover{
        animation: pulsate 1s
        ease-in-out;
      }
      .Button:disabled {
        color: rgb(218, 209, 184);
        border: 3px solid #97a5cd;
        background-color: rgb(145, 164, 219);
        animation: pulsate 0s
        ease-in-out;
      }
      
      @keyframes pulsate{
        0%{
            box-shadow:
            0 0 2vw #15258c,
            0 0 5vw #1842cc;
          }
      }
    
    
}