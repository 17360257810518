nav {
	width: 100%;
	position: sticky;
	z-index: 10;
	top: 0;
	padding: 1.2vh 1vh;

	color: rgb(17, 8, 47);
	background-color: rgb(242, 243, 244);
	max-height: 4em;
	text-align: center;

}

nav span {
	font-size: 3vh;
	border-bottom: 0.2vh solid transparent;
	transition: border-bottom 0.02s ease;
	transition-delay: 0.05s;
	margin: 3.5vh;
	padding-bottom: 0.3em;
}

nav span:hover {
	cursor: pointer;
	border-bottom: 0.2vh solid rgb(15, 33, 47);
}

nav span:hover i {
	color: gold;
}

nav span.activeClass i {
	color: gold;
}

nav span.activeClass {
	border-bottom-color: var(--lightAccent);
}

@media screen and (max-width: 1000px) {
	nav {
		width: 100%;
		position: sticky;
		z-index: 10;
		top: 0;
		padding: 1.2em 0;
		color: rgb(17, 8, 47);
		background-color: rgb(242, 243, 244);
		max-height: 4em;
	}
	
	nav span {
		font-size: 2.7vw;
		border-bottom: 0.3vw solid transparent;
		transition: border-bottom 0.02s ease;
		transition-delay: 0.05s;
		margin: 1em;
		padding-bottom: 0.3vw;
	}

	nav span:hover {
		cursor: pointer;
		border-bottom: 0.2vw solid rgb(15, 33, 47);
	}
}