section {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
	border-bottom: 1px solid rgb(46, 43, 43);
}

section:nth-child(even) {
	flex-direction: row-reverse;
}

section img,
section div {
	height: auto;
	width: 100%;
}

.portfolioLinks{
    width:15vw;
    height:9vw;
    margin-right:3vw;
    margin-left:1vw
}
.gh{
    color: rgb(21, 59, 84);
}

section div {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
	padding: 1em;
}
h2{
    font-family: Verdana, Tahoma, sans-serif;
}
h3{
    font-family: Verdana, Tahoma, sans-serif;
}

h4{
    font-family: Verdana, Tahoma, sans-serif;
}

section p{
    font-family: Verdana, Tahoma, sans-serif;
    font-size: medium;
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content:flex-start;
}

.Button{
    display: inline-block;
    background-color: rgb(38, 29, 96);
    color: rgb(221, 222, 239);
    font-size: 1.5vw;
    padding: 1vw 3vw;
    border: 2px solid #131f44;
    border-radius: 0.5vw;
    margin: 2vw 3vw;
    cursor: pointer;
    align-items: flex-start;
	justify-content: flex-start;
  }
  .Button:hover{
    animation: pulsate 1s
    ease-in-out;
  }
  .Button:disabled {
    color: rgb(218, 209, 184);
    border: 3px solid #97a5cd;
    background-color: rgb(145, 164, 219);
    animation: pulsate 0s
    ease-in-out;
  }
  
  @keyframes pulsate{
    0%{
      box-shadow:
      0 0 25px #5ddcff,
      0 0 50px #4e00c2;
    }
  }

@media screen and (max-width: 1000px) {
	section,
	section:nth-child(even) {
		flex-direction: row;
        height:100vh;
	}
    section div {
        width:fit-content;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: space-around;
        padding: 1em;
        
    }
    
    .portfolioLinks{
        width:0.5vw;
        height:0.2vw;
        margin-right:0.3vw;
        margin-left:0.1vw
    }
    .about{
        margin-top: 16vw;
    }

    h2{
        font-family: Verdana, Tahoma, sans-serif;
        font-size: small;
    }
    h3{
        font-family: Verdana, Tahoma, sans-serif;
        font-size: small;
    }
    
    h4{
        font-family: Verdana, Tahoma, sans-serif;
        font-size: small;
    }
    section p{
        width:fit-content;
        display: flex;
        flex-direction: row;
        align-items:flex-start;
        justify-content:flex-start;
        font-size: x-small;
    }

    .Button{
        display: inline-block;
        background-color: rgb(207, 164, 70);
        color: rgb(237, 223, 169);
        font-size: 1em;
        padding: 0.25em 0.5em;
        border: 2px solid #131f44;
        border-radius: 0.5em;
        margin: 10px 0px;
        cursor: pointer;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .Button:hover{
        animation: pulsate 1s
        ease-in-out;
      }
      .Button:disabled {
        color: rgb(218, 209, 184);
        border: 3px solid #97a5cd;
        background-color: rgb(145, 164, 219);
        animation: pulsate 0s
        ease-in-out;
      }
      
      @keyframes pulsate{
        0%{
          box-shadow:
          0 0 25px #5ddcff,
          0 0 50px #4e00c2;
        }
      }
    
    
}