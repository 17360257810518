section {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
	border-bottom: 1px solid rgb(46, 43, 43);
    background-image: url("https://wallup.net/dark-nature-trees-sunlight/");
}

section:nth-child(even) {
	flex-direction: row-reverse;
}

section img,
section div {
	height: auto;
	width: 100%;
}

/* section img {
	width: 50%;
} */

.homeFont{
    font-size:2.5vw;
    margin-top: 1.3vw;
    margin-left: 1.2vw;
    color:rgb(21, 59, 84)
}
.homeFont2{
    font-size:1.5vw;
    margin-left: 10vw;
    color:rgb(21, 59, 84)
}
.profileImage{
    height: 6vw;
	width: 5vw;
    max-width: 6vw;
    margin-left:24vw;
}

.portfolioLink{
    height: 5vw;
	width: 5.5vw;
	max-width: 10vw;
    padding: 1vw;
    margin-right:1vw;
    margin-left:28vw;
    margin-top: 1vw;
}

section div {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 1em;
}
h2{
    font-family: Verdana, Tahoma, sans-serif;
}
h3{
    font-family: Verdana, Tahoma, sans-serif;
    color: rgb(12, 6, 36);
}

h4{
    font-family: Verdana, Tahoma, sans-serif;
}
p{
    color: rgb(12, 6, 36);
}

@media screen and (min-device-width: 375px) and (max-width: 812px) {
	section,
	section:nth-child(even) {
		flex-direction: column;
	}
    section div {
        width:fit-content;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: space-around;
        padding: 1em;
    }
    .homeFont{
        font-size:6vw;
        margin-top: -13vw;
        margin-left: 2vw;
        color:rgb(21, 59, 84)
    }
    .homeFont2{
        font-size:3vw;
        margin-left: 8vw;
        color:rgb(21, 59, 84)
    }
    .profileImage{
        height: 15vw;
		width: 16vw;
		max-width: 16vw;
        margin-left:14vw;
        margin-top: -13vw;
    }
    
    .portfolioLink{
        height: 8vw;
		width: 8.5vw;
		max-width: 10vw;
        margin-left: 25vw;
    }

    h2{
        font-family: Verdana, Tahoma, sans-serif;
        font-size: small;
    }
    h3{
        font-family: Verdana, Tahoma, sans-serif;
        font-size: small;
    }
    
    h4{
        font-family: Verdana, Tahoma, sans-serif;
        font-size: small;
    }
    p{
        font-family: Verdana, Tahoma, sans-serif;
        font-size: small;
    }
}